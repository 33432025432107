import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import * as React from "react";
import { useMediaQuery } from "react-responsive";
import Aside from "../../components/Aside";
import Page from "../../components/Page";
import * as P from "../../components/Page/styles";
import SubAreas from "../../components/SubAreas";
import Seo from "../../components/seo";

const DeathBenefit = () => {
  const { background } = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "banner.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              quality: 100
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );

  const image = getImage(background);
  const isDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <React.Fragment>
      <Page
        title="Pensão por Morte"
        image={image}
        breadcrumb={[
          { page: "/", name: "Início" },
          {
            page: "/aposentadoria-e-auxilios",
            name: "Aposentadoria e Auxílios",
          },
          { page: "/pensao-por-morte", name: "Pensão por Morte" },
        ]}
      >
        {isDesktop && <Aside withContact />}

        <P.Article>
          <p>
            Pensão por morte é o benefício concedido aos dependentes do segurado
            que vier a falecer.
          </p>
          <p>
            Para ter acesso ao benefício, faz-se necessário comprovar que o
            falecido possuía qualidade de segurado perante o INSS à data do
            óbito. Se o falecido já recebia algum benefício do INSS, presume-se
            que à data do óbito possuía tal qualidade, exceto se recebia o
            BPC/Loas.
          </p>
          <p>
            Se o falecido trabalhava com a carteira assinada, provavelmente
            também deixará pensão por morte aos dependentes.
          </p>
          <p>
            A duração da pensão por morte varia não sendo o mesmo tempo para
            toda e qualquer pessoa.
          </p>

          <P.Table>
            <thead>
              <tr>
                <th>Idade do dependente na data do óbito</th>
                <th>Duração máxima do benefício ou cota</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Menos de 21 anos</td>
                <td>3 anos</td>
              </tr>
              <tr>
                <td>Entre 21 e 26 anos</td>
                <td>6 anos</td>
              </tr>
              <tr>
                <td>Entre 27 e 29 anos</td>
                <td>10 anos</td>
              </tr>
              <tr>
                <td>Entre 30 e 40 anos</td>
                <td>15 anos</td>
              </tr>
              <tr>
                <td>Entre 41 e 43 anos</td>
                <td>20 anos</td>
              </tr>
              <tr>
                <td>A partir de 44 anos</td>
                <td>Vitalício</td>
              </tr>
            </tbody>
          </P.Table>

          <p>
            <b>
              Para o cônjuge, companheiro, cônjuge divorciado ou separado
              judicialmente ou de fato que recebia pensão alimentícia
            </b>
            , válido tanto para homens quanto para mulheres, o benefício será
            concedido por 4 meses a contar da data do óbito se o óbito ocorrer
            sem que o segurado tenha realizado 18 contribuições mensais à
            Previdência ou se o casamento ou união estável se iniciou em menos
            de 2 anos antes do falecimento do segurado.
          </p>
          <p>
            Vale ressaltar que se o óbito vier através de acidente,
            independentemente da quantidade de contribuições ou do tempo de
            casamento/união estável, a duração acompanhará o exposto na tabela
            por acima.
          </p>
          <p>
            <b>Para o cônjuge inválido ou com deficiência</b>, o benefício de
            pensão por morte é devido enquanto durar a deficiência ou invalidez,
            em caso de cessar a incapacidade ou invalidez, deve-se respeitar os
            prazos mínimos descritos na tabela acima.
          </p>
          <p>
            Destaca-se que com a alteração da legislação em 2019 os pensionistas
            receberão 50% do benefício +10% para cada dependente, ou seja,
            inicia-se com 60% do valor do benefício, podendo chegar a 100% do
            valor em razão de mais dependentes, vale ressaltar que o valor
            mínimo deve ser igual ao salário mínimo.
          </p>

          <P.Table>
            <thead>
              <tr>
                <th>Quantidade de dependentes</th>
                <th>Porcentagem que os dependentes têm direito</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>60%</td>
              </tr>
              <tr>
                <td>2</td>
                <td>70%</td>
              </tr>
              <tr>
                <td>3</td>
                <td>80%</td>
              </tr>
              <tr>
                <td>4</td>
                <td>90%</td>
              </tr>
              <tr>
                <td>5</td>
                <td>100% (Limite)</td>
              </tr>
              <tr>
                <td>6</td>
                <td>100%</td>
              </tr>
            </tbody>
          </P.Table>

          <p>
            <b>
              Pensão por morte para irmãos do falecido e filhos (equiparados)
            </b>
            , desde que comprovem o direito, o benefício é devido até os 21 anos
            de idade, salvo em caso de invalidez ou deficiência.
          </p>

          <p>
            Também é possível receber mais de uma pensão, neste caso existem os
            modelos específicos que possibilitam a acumulação, em caso de
            dúvidas podemos analisar o seu caso e informa-lo se é possível seu
            enquadramento.
          </p>
        </P.Article>

        {!isDesktop && <Aside withContact />}
      </Page>

      <SubAreas />
    </React.Fragment>
  );
};

export const Head = () => (
  <Seo
    title="Pensão por Morte"
    description="Atendimento jurídico especializado em Pensão por Morte. Oferecemos orientação para garantir seus direitos a benefícios e apoio durante o processo, com atendimento confiável e humano."
  />
);

export default DeathBenefit;
